<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VContainer fluid>
            <VRow justify="space-between">
              <VCol>
                <h1>{{ $t('create_content.header') }}</h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol md="3">
                <h3>{{ $t('create_content.sub_header') }}</h3>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VForm
                  ref="form"
                  v-model="isValid"
                  lazy-validation
                >
                  <VRow>
                    <VCol>
                      <VRadioGroup
                        v-model="selectedContentType"
                        :rules="[rules.required]"
                        row
                      >
                        <VRadio
                          :label="$t('create_content.multi')"
                          :value="$options.ContentTypes.HTML"
                        />
                        <VRadio
                          :label="$t('create_content.video')"
                          :value="$options.ContentTypes.VIDEO"
                        />
                      </VRadioGroup>
                    </VCol>
                  </VRow>
                  <VRow v-if="isHtmlContent">
                    <VCol>
                      <TTTextField
                        v-model="content.title"
                        :label="$t('create_content.title')"
                        :rules="[rules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow v-if="isHtmlContent">
                    <VCol>
                      <label>{{ $t('create_content.description') }}</label>
                      <TTTextarea
                        v-model="content.description"
                        class="mt-3"
                      />
                    </VCol>
                  </VRow>
                  <VRow v-if="isHtmlContent">
                    <VCol>
                      <label>{{ $t('create_content.body') }}</label>
                      <TTTextarea
                        v-model="content.body"
                        :rules="[rules.htmlRequired]"
                        class="mt-3"
                        :maxlength="false"
                      />
                    </VCol>
                  </VRow>
                  <VRow v-if="isVideoContent">
                    <VCol>
                      <label>{{ $t('create_content.file') }}</label>
                      <VFileInput
                        v-model="file"
                        class="mt-3 tt-light-mono-4"
                        :rules="[rules.videoFileRequired]"
                        outlined
                        accept="video/*"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <TTBtn
                        :loading="contentItems.loading"
                        :disabled="!isValid || contentItems.loading"
                        @click="submit"
                      >
                        {{ $t('create_content.submit_btn') }}
                      </TTBtn>
                    </VCol>
                  </VRow>
                </VForm>
              </VCol>
            </VRow>
          </VContainer>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <TTDataTable
          :headers="headers"
          :items="contentItems.values"
          :loading="contentItems.loading"
          disable-pagination
          disable-sort
        >
          <template #item.id="{ item }">
            <span class="word-break-all">
              {{ item.id }}
            </span>
          </template>

          <template #item.status="{ item }">
            <span class="word-break-all">
              {{ item.status }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <TTBtn
                  icon
                  fab
                  color="tt-light-yellow"
                  v-bind="attrs"
                  :to="getLink(item)"
                  v-on="on"
                >
                  <VIcon>
                    $edit
                  </VIcon>
                </TTBtn>
              </template>
              <span>{{ $t('create_content.edit') }}</span>
            </VTooltip>
          </template>
        </TTDataTable>
        <VPagination
          v-if="contentItemsPagesTotal > 0"
          v-model="contentItems.page"
          total-visible="10"
          :length="contentItemsPagesTotal"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { ContentTypes } from '../../common/constants';

export default {
  name: 'CreateContent',
  inject: ['Names'],
  data() {
    return {
      selectedContentType: this.$options.ContentTypes.HTML,
      content: {
        title: '',
        description: '',
        body: '',
        copyrightId: null,
      },
      file: null,
      isValid: false,
      contentItems: {
        values: [],
        total: 0,
        page: 1,
        pageSize: 20,
        loading: false,
      },
      copyrights: [],
      headers: [
        { text: 'Id', value: 'id', width: '30%' },
        { text: 'Название', value: 'title', width: '35%' },
        { text: 'Статус', value: 'status', width: '25%' },
        { text: 'Действия', value: 'actions', width: '10%' },
      ],
      rules: {
        required: (v) => !!v.trim().length > 0 || 'Обязательное поле',
        htmlRequired: (v) => (this.isHtmlContent && this.isValidJson(v)) || 'Обязательно валидный JSON ',
        videoCopyrightRequired: (v) => (this.isVideoContent && !!v) || 'Обязательное поле',
        videoFileRequired: (v) => (this.isVideoContent && !!v && this.isVideoFile(v)) || 'Обязательное поле',
      },
      archiveDialog: false,
      archiveItem: {
        id: null,
      },
    };
  },
  ContentTypes,
  computed: {
    isVideoContent() {
      return this.selectedContentType === this.$options.ContentTypes.VIDEO;
    },
    isHtmlContent() {
      return this.selectedContentType === this.$options.ContentTypes.HTML;
    },
    contentItemsPagesTotal() {
      return Math.ceil(this.contentItems.total / this.contentItems.pageSize);
    },
    autocompleteClass() {
      return this.content.copyrightId ? 'ml-1 mt-3' : 'ml-4 mt-3';
    },
    jsonValid() {
      return false;
    },
  },
  watch: {
    'contentItems.page': {
      async handler(pageNumber) {
        const {
          contents = [],
          pagination: { total = 0 },
        } = await this.fetchContentItemsPage(pageNumber, this.contentItems.pageSize);
        this.contentItems.values = contents;
        this.contentItems.total = total;
      },
      immediate: true,
    },
  },
  async created() {
    await this.getCopyrights();
  },
  methods: {
    isVideoFile(v) {
      // NOTE: проверка на тип файла
      return v.type.includes('video/');
    },
    isValidJson(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.saveContent();
      }
    },
    async saveContent() {
      try {
        this.contentItems.loading = true;

        if (this.isHtmlContent) {
          await this.saveHtmlContent();
        }
        if (this.isVideoContent) {
          await this.saveVideoContent();
        }
      } finally {
        this.clearFilds();
        this.contentItems.loading = false;
      }
    },
    async saveHtmlContent() {
      try {
        await this.$di.api.MultiContent.sandboxCreate({
          content: {
            title: this.content.title,
            description: this.content.description,
            body: this.content.body,
          },
        });
        this.$di.notify.snackSuccess(this.$t('create_content.result_success'));
        const { contents = [] } = await this.fetchContentItemsPage(this.contentItems.page);
        this.contentItems.values = contents;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async saveVideoContent() {
      try {
        const { id, videoUploadUrl } = await this.$di.api.MultiContent.videoCreate({
          video: {
            extension: this.file.name.split('.').pop(),
            mimetype: this.file.type,
            copyrightId: this.content.copyrightId,
          },
        });
        await this.cloudUpload(videoUploadUrl);
        await this.uploadVideo(id);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async cloudUpload(videoUploadUrl) {
      try {
        /**
       * Note: Content-Type всегда MIME тип файла
       * @see https://cloud.yandex.ru/docs/storage/concepts/presigned-post-forms#form-fields Описание полей формы
       * */
        const headers = {
          'Content-Type': this.file.type,
        };

        const config = {
          method: 'PUT',
          body: this.file,
          headers,
        };

        /**
       * Note: Запрос не работает из-за CORS.
       * Проверить работоспособность запроса можно отключив CORS в браузере или на stage/production окружении
       * */
        /** Note: Здесь используется простой fetch */
        await this.$di.api.CloudStorage
          .fileStorageUpload(videoUploadUrl, config);
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async uploadVideo(id) {
      try {
        const data = { id, copyright_id: this.content.copyrightId };
        await this.$di.api.MultiContent.videoUpload({ data });

        this.$di.notify.snackSuccess(this.$t('create_content.result_success'));
        const { contents = [] } = await this.fetchContentItemsPage(this.contentItems.page);
        this.contentItems.values = contents;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    async fetchContentItemsPage(page = 1, limit = this.contentItems.pageSize) {
      try {
        return this.$di.api.MultiContent.sandboxIndex({ page, limit });
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      }
    },
    async getCopyrights() {
      try {
        const { copyrights } = await this.$di.api.TtCourse.copyrightIndex();

        this.copyrights = copyrights;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },
    clearFilds() {
      this.content.title = '';
      this.content.description = '';
      this.content.body = '';
      this.content.copyrightId = null;
      this.file = null;
      this.$refs.form.resetValidation();
    },
    autocompleteClear() {
      this.content.copyrightId = null;
    },
    getLink(item) {
      return { name: this.Names.R_LEARNING_COURSE_V2_CONTENT_HTML_EDIT, params: { contentId: item.id } };
    },
  },
};
</script>

<style lang="scss" scoped>
.word-break-all {
  word-break: break-all;
}
</style>
