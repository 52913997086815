export const EXAM_STATUS = {
  active: 'active',
  completed: 'completed',
};

/**
 * @typedef {Enumerator<string>} ExamStatusEnum
 * @readonly
 * @enum {string}
 */
export const ExamStatusEnum = {
  NOT_RATED: 'NOT_RATED',
  FAILED: 'FAILED',
  SUCCESSFUL: 'SUCCESSFUL',
};

export const SKILL_EXAM_STATUS = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
};

export const ContentStorageType = {
  CONTENT_TYPE_VIDEO_HLS: 'CONTENT_TYPE_VIDEO_HLS',
  CONTENT_TYPE_TEXT_HTML: 'CONTENT_TYPE_TEXT_HTML',
  CONTENT_TYPE_TEXT_PLAIN: 'CONTENT_TYPE_TEXT_PLAIN',
};

export const redirectUrls = {
  gatekeeper: process.env.VUE_APP_GATEKEEPER_ADDRESS || 'http://localhost:3000/',
  archivist: process.env.VUE_APP_ARCHIVIST_ADDRESS || 'http://localhost:3000/',
};

export const QuestionType = {
  POLY: 'poly',
  MONO: 'mono',
};

export const InstrumentType = {
  INVALID: 'invalid',
  SKILLDEV: 'skilldev',
  MULTILEVEL_TEST: 'multi_level_test',
  EXPERT_ASSESSMENT: 'expert_assessment',
  VERIFICATION: 'verification',
};

export const LevelEnum = {
  LEVEL_INVALID: 'LEVEL_INVALID',
  LEVEL_NOVICE: 'LEVEL_NOVICE',
  LEVEL_BASIC: 'LEVEL_BASIC',
  LEVEL_PROFICIENT: 'LEVEL_PROFICIENT',
  LEVEL_ADVANCED: 'LEVEL_ADVANCED',
  LEVEL_EXPERT: 'LEVEL_EXPERT',
};

export const LevelNameMap = {
  [LevelEnum.LEVEL_INVALID]: 'Не определён',
  [LevelEnum.LEVEL_NOVICE]: 'Нулевой',
  [LevelEnum.LEVEL_BASIC]: 'Базовый',
  [LevelEnum.LEVEL_PROFICIENT]: 'Средний',
  [LevelEnum.LEVEL_ADVANCED]: 'Продвинутый',
  [LevelEnum.LEVEL_EXPERT]: 'Экспертный',
};

export const InstrumentStatusEnum = {
  NEXT_RESULT: 'NEXT_RESULT',
  NEXT_RESUME: 'NEXT_RESUME',
  NEXT_BEGIN: 'NEXT_BEGIN',
  NEXT_INVALID: 'NEXT_INVALID',
};

export const ReviewStepsMap = {
  CONTINUE: 'continue',
  FINISHED: 'finished',
  SHOW_RIGHT_ANSWER: 'show_right_answer',
};

export const RoleEnum = {
  ADMIN: 'admin',
  USER: 'user',
  CHIEF: 'chief',
};

export const ContentTypes = {
  HTML: 'CONTENT_TYPE_TEXT_HTML',
  VIDEO: 'CONTENT_TYPE_VIDEO_HLS',
};

export const CourseStatus = {
  STATUS_INVALID: 'STATUS_INVALID',
  STATUS_INITIAL: 'STATUS_INITIAL',
  STATUS_ACTIVE: 'STATUS_ACTIVE',
  STATUS_DONE: 'STATUS_DONE',
  STATUS_BLOCKED: 'STATUS_BLOCKED',
};
